/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-down-left-square': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M15 2a1 1 0 00-1-1H2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1zM0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2zm10.096 3.146a.5.5 0 11.707.708L6.707 9.95h2.768a.5.5 0 110 1H5.5a.5.5 0 01-.5-.5V6.475a.5.5 0 111 0v2.768z"/>',
    },
});
